import React from 'react'
import '../assets/styles/program.scss'

const Program = () => {
  const skillsAndColors = [
    { skill: 'HTML', color: '#E44D26' },
    { skill: 'CSS', color: '#264DE4' },
    { skill: 'JavaScript', color: '#F7DF1E' },
    { skill: 'TypeScript', color: '#3178C6' },
    { skill: 'React', color: '#61DAFB' },
    { skill: 'Redux', color: '#764ABC' },
    { skill: 'Node.js', color: '#68A063' },
    { skill: 'MySQL', color: '#00758F' },
    { skill: 'MongoDB', color: '#47A248' },
  ]

  return (
    <div title='Programs'>
      <div className='program'>
        <h1> Igniting Your Tech Journey at DevOsome CodeCamp</h1>
        <p>
          At DevOsome, we are more than just a coding bootcamp – we are your
          dedicated partner on your journey to becoming a proficient tech
          professional. Our tailored programs are designed to empower
          individuals at every stage of their tech career, whether you are a
          beginner, an intermediate coder, or an aspiring data analyst.
        </p>
        <h3>Developer</h3>
        <p>
          DevOsome Full-Stack Coding Bootcamps provide a guided path to success.
          Whether you are a complete beginner or an intermediate programmer, our
          well-structured, hands-on curricula are tailored to your skill level.
          You'll dive into the fascinating world of web development, mastering
          core technologies such as:
        </p>
        <ul className='techs'>
          {skillsAndColors.map((item) => (
            <li
              key={item.skill}
              style={{
                backgroundColor: item.color,
              }}
            >
              {item.skill}
            </li>
          ))}
        </ul>
        <p>
          You will explore full-stack development, learn about React, Node.js,
          and build real-world applications. Our comprehensive programs give you
          the confidence to tackle complex coding projects and set you up for a
          thriving tech career.
        </p>
        <h3>Data Analysts</h3>
        <p>
          Our DevOsome Data Analysis Bootcamp is a transformative 6-month
          journey into the realm of data analysis. Guided by seasoned experts,
          you will master essential tools and technologies, including:
        </p>
        <ul className='techs'>
          {[
            { skill: 'Python', color: '#3776AB' },
            { skill: 'NumPy', color: '#013243' },
            { skill: 'Pandas', color: '#150458' },
            { skill: 'Matplotlib', color: '#FF7043' },
          ].map((item) => (
            <li key={item.skill} style={{ backgroundColor: item.color }}>
              {item.skill}
            </li>
          ))}
        </ul>
        <p>
          This intensive program covers data wrangling, data visualization,
          statistical analysis, machine learning, and much more. By the end of
          the course, you'll have the skills to dissect datasets, extract
          insights, and make data-driven decisions with precision.
        </p>
        <p>
          In the heart of each program is a belief in your potential. We offer
          not just knowledge and skills but the confidence to turn your coding
          dreams into reality or dive deep into the world of data analysis. At
          DevOsome, we are committed to your success, offering guidance,
          challenging your limits, and providing the support needed at every
          step.
        </p>
        <p>
          Our vibrant and innovative community of learners, industry
          professionals, and expert instructors is the perfect environment to
          grow your tech skills. Through coding bootcamps or data analysis
          programs, we aim to transform you into a tech professional ready to
          face the challenges of the ever-evolving tech world.
        </p>
        <p>
          Are you ready to embark on a tech journey with precision and purpose?
          Join DevOsome today, and together we'll ignite your passion for coding
          or data analysis and set you on a path to a fulfilling tech career.
        </p>
        <details>
          <summary>
            <strong> Fullstack Program</strong>
          </summary>
          <div className='program'>
            <h3>
              <strong>Duration: 9-12 months (full-time)</strong>
            </h3>
            <h3>Starting date: January 8, 2024</h3>
            <p>
              Our Fullstack Web Development program is designed to equip you
              with a comprehensive skill set in this dynamic field. Covering
              both front-end and back-end development, this program spans 9-12
              months to accommodate various schedules. It is friendly for
              absolute beginners or starters.
            </p>
            <h3>
              <strong>Prerequisites:</strong>
            </h3>
            <p>
              Before diving into the program, it's essential to have basic
              computer skills and be comfortable using a web browser. The
              program is English and it requires proficency in English
            </p>
            <h3>
              <strong>Course Modules:</strong>
            </h3>
            <h4>
              <strong>Module 1: Introduction to Web Development</strong>
            </h4>
            <ul>
              <li>Understanding the core aspects of web development</li>
              <li>Explore essential web technologies</li>
              <li>
                Grasp the client-server model and the significance of HTTP/HTTPS
              </li>
              <li>Hands-on exercises to reinforce your knowledge</li>
            </ul>
            <h4>
              <strong>Module 2: Web Design Principles</strong>
            </h4>
            <ul>
              <li>Fundamentals of UI/UX design</li>
              <li>Color theory and typography</li>
              <li>Design tools such as Figma</li>
              <li>
                Prototyping and wireframing through hands-on design projects
              </li>
            </ul>
            <h4>
              <strong>Module 3: Web Development Tools</strong>
            </h4>
            <ul>
              <li>Basic command lines or Unix</li>
              <li>Version control using Git</li>
              <li>
                Choose and optimize text editors and IDEs like Visual Studio
                Code
              </li>
              <li>Explore web development extensions and plugins</li>
              <li>Debugging and utilizing browser developer tools</li>
            </ul>
            <h4>
              <strong>Module 4: HTML (Hyper Text Markup Language)</strong>
            </h4>
            <ul>
              <li>The basics of HTML, covering structure and elements</li>
              <li>Learn how to format text and create hyperlinks</li>
              <li>
                Build a simple website from scratch to apply your newfound
                knowledge
              </li>
            </ul>
            <h4>
              <strong>Module 5: Cascading Style Sheets (CSS)</strong>
            </h4>
            <ul>
              <li>Introduction to CSS and its core concepts</li>
              <li>Understand selectors, properties, and values</li>
              <li>
                Style text, links, and layouts to create attractive designs
              </li>
              <li>
                Create responsive designs by exploring principles, media
                queries, fluid grids, and CSS frameworks
              </li>
              <li>
                Focus on web accessibility, adhering to WCAG guidelines and
                ensuring cross-browser compatibility
              </li>
              <li>
                Mobile-first design approach while working on hands-on projects
              </li>
            </ul>
            <h4>
              <strong>Module 6: JavaScript</strong>
            </h4>
            <ul>
              <li>
                Fundamentals of JavaScript, including variables, data types, and
                operators
              </li>
              <li>
                Control structures such as if statements, while loops, and for
                loops
              </li>
              <li>Functions and events</li>
              <li>
                Document Object Model (DOM) and tackle hands-on coding
                challenges
              </li>
              <li>Hands-on coding challenges</li>
            </ul>
            <h4>
              <strong>Module 7: Front-End Frameworks</strong>
            </h4>
            <ul>
              <li>
                Introduction to front-end frameworks, with a focus on React
              </li>
              <li>Understand component-based architecture</li>
              <li>
                Build interactive web applications while mastering state
                management
              </li>
              <li>
                Unit testing, test-driven development, and end-to-end testing as
                you work on engaging projects
              </li>
            </ul>
            <h4>
              <strong>Module 8: Back-End Development</strong>
            </h4>
            <ul>
              <li>
                Back-end development with an introduction to server-side
                languages like Node.js and Python
              </li>
              <li>Explore databases, both SQL and NoSQL</li>
              <li>Understand database design and normalization</li>
              <li>
                Learn about CRUD operations (Create, Read, Update, Delete)
              </li>
              <li>
                Building APIs and handling authentication and authorization
              </li>
              <li>
                Unit testing, test-driven development, and end-to-end testing
                through hands-on projects
              </li>
            </ul>
            <h4>
              <strong>Module 9: Web Hosting and Deployment</strong>
            </h4>
            <ul>
              <li>
                Web hosting providers and domain registration along with DNS
                management
              </li>
              <li>
                Explore various hosting options, including shared hosting, VPS,
                and cloud solutions
              </li>
              <li>
                Learn deployment techniques such as FTP, SSH, and CI/CD
                pipelines
              </li>
              <li>Deploy websites and applications effectively</li>
              <li>
                Optimize performance through techniques like lazy loading, CDN
                implementation, page speed optimization, and image optimization
              </li>
              <li>
                Explore caching strategies and embrace security best practices,
                covering common vulnerabilities like Cross-Site Scripting and
                SQL Injection
              </li>
              <li>
                Learn about HTTPS and SSL certificates to enhance security
              </li>
            </ul>
            <h3>
              <strong>Final Project: Showcase Your Skills</strong>
            </h3>
            <p>
              At the end of your training, you will undertake a substantial
              project that serves as a showcase of your web development and
              design skills. This project will incorporate both front-end and
              back-end development, design principles, and the best practices
              you've learned throughout the curriculum.
            </p>
          </div>
        </details>
        <details>
          <summary>
            <strong>Fullstack Fast Track</strong>
          </summary>

          <div className='program'>
            <h3>
              <strong>Prerequisites:</strong>
            </h3>
            <p>
              Before diving into the program, it's essential to have some level
              of HTML, CSS, JavaScript, React, Node and MongoDB. It requires
              some level of coding background.
            </p>
            <h3>
              <strong>Module 1: Front-End Development</strong>
            </h3>
            <h4>
              <em>Part 1: Web Fundamentals</em>
            </h4>
            <ul>
              <li>HTML basics, including semantic elements</li>
              <li>CSS fundamentals, covering selectors and properties</li>
              <li>Introduction to responsive design and media queries</li>
              <li>
                <strong>Hands-on Project:</strong> Create a basic personal
                website with HTML and CSS, applying responsive design
                principles.
              </li>
            </ul>
            <h4>
              <em>Part 2: Advanced Front-End</em>
            </h4>
            <ul>
              <li>
                Mastery of ES6+ JavaScript features, such as arrow functions,
                classes, and modules
              </li>
              <li>Promises and async/await</li>
              <li>Event handling and callbacks</li>
              <li>Introduction to TypeScript for front-end development</li>
              <li>
                Dive into React components, state management with Redux, and
                props
              </li>
              <li>Routing in React applications</li>
              <li>Working with APIs in a React environment</li>
              <li>
                <strong>Hands-on Project:</strong> Develop a React application
                with Redux for state management, connecting to a public API to
                display dynamic content.
              </li>
            </ul>
            <h3>
              <strong>Module 2: Back-End Development</strong>
            </h3>
            <h4>
              <em>Part 3: Back-End Basics</em>
            </h4>
            <ul>
              <li>Understanding the basics of server-side scripting</li>
              <li>Exploring Node.js and Express.js</li>
              <li>Building a basic API</li>
              <li>
                <strong>Hands-on Project:</strong> Create a RESTful API with
                Node.js and Express for a simple task management application.
              </li>
            </ul>
            <h4>
              <em>Part 4: Advanced Back-End and Databases</em>
            </h4>
            <ul>
              <li>Implementing user authentication</li>
              <li>Utilizing JSON Web Tokens (JWT) for secure access</li>
              <li>Managing role-based access control</li>
              <li>Designing advanced APIs with best practices</li>
              <li>Implementing error handling and data validation</li>
              <li>Testing APIs using tools like Postman</li>
              <li>
                <strong>Hands-on Project:</strong> Enhance the previous task
                management API with user authentication and role-based access
                control.
              </li>
            </ul>
            <h3>
              <strong>Module 3: Full-Stack Development</strong>
            </h3>
            <h4>
              <em>Part 5: Full-Stack Integration</em>
            </h4>
            <ul>
              <li>
                Connecting the front-end (React) with the back-end (Express)
              </li>
              <li>Retrieving data from a RESTful API in the front-end</li>
              <li>Implementing state management using Redux or Context API</li>
              <li>
                <strong>Hands-on Project:</strong> Combine the front-end and
                back-end to create a full-stack task management application with
                user registration and login.
              </li>
            </ul>
            <h4>
              <em>Part 6: Advanced Topics and Final Projects</em>
            </h4>
            <ul>
              <li>
                Deepening knowledge of state management with Redux or Context
                API
              </li>
              <li>Implementing routing and navigation using React Router</li>
              <li>Building complex user interfaces</li>
              <li>Working on a full-stack web project of your choice</li>
              <li>
                Specializing in areas like mobile web development, real-time
                apps, or serverless architecture
              </li>
              <li>
                <strong>Hands-on Project:</strong> Develop a final full-stack
                projects such as blog, e-commerce, educational platform, a
                real-time chat application, a serverless API, or a mobile web
                application.
              </li>
            </ul>
            <p>
              Throughout the course, students practice regularly, and work on
              projects to reinforce their skills. Building real-world
              applications will solidify their understanding, and each part
              includes hands-on projects to apply their knowledge.
            </p>
          </div>
        </details>

        <details>
          <summary>
            <strong>Data Analysis</strong>
          </summary>
          <div className='program'>
            <p>
              Welcome to the DevOsome Data Analysis Bootcamp, a transformative
              6-month journey into the world of data analysis. This
              comprehensive curriculum is designed to equip you with the
              essential skills and tools to become a proficient data analyst.
              The core technologies you will master include Python, NumPy,
              Pandas, Matplotlib, and other critical data analysis libraries and
              tools.
            </p>
            <h3>
              <strong>Module 1: Introduction to Data Analysis (2 weeks)</strong>
            </h3>
            <ul>
              <li>Understanding the data analysis process</li>
              <li>Introduction to Python for data analysis</li>
              <li>Setting up your data analysis environment</li>
              <li>Basic data exploration and visualization with Matplotlib</li>
            </ul>
            <h3>
              <strong>Module 2: Python for Data Analysis (3 weeks)</strong>
            </h3>
            <ul>
              <li>Python programming basics</li>
              <li>Data types, variables, and data structures</li>
              <li>Control flow and loops</li>
              <li>Functions and modules in Python</li>
              <li>File handling</li>
              <li>Regular Expression</li>
              <li>Classes and Objects</li>
            </ul>
            <h3>
              <strong>Module 3: Data Wrangling with Pandas (4 weeks)</strong>
            </h3>
            <ul>
              <li>Introduction to Pandas: Series and DataFrames</li>
              <li>Data cleaning and preprocessing</li>
              <li>Data manipulation, filtering, and sorting</li>
              <li>Merging and joining datasets</li>
              <li>Handling missing data</li>
              <li>Time series data analysis</li>
            </ul>
            <h3>
              <strong>
                Module 4: Data Visualization with Matplotlib and Seaborn (4
                weeks)
              </strong>
            </h3>
            <ul>
              <li>Creating basic plots with Matplotlib</li>
              <li>Customizing plots: labels, titles, and styles</li>
              <li>Exploratory data visualization</li>
              <li>Advanced data visualization with Seaborn</li>
              <li>Creating interactive visualizations</li>
            </ul>
            <h3>
              <strong>Module 5: Advanced Data Analysis (6 weeks)</strong>
            </h3>
            <ul>
              <li>Advanced data transformation and aggregation with Pandas</li>
              <li>Statistical analysis and hypothesis testing</li>
              <li>Machine learning for data analysis</li>
              <li>Working with large datasets</li>
              <li>Introduction to data mining and clustering</li>
            </ul>
            <h3>
              <strong>
                Module 6: Real-World Data Analysis Projects (7 weeks)
              </strong>
            </h3>
            <ul>
              <li>Capstone project: Analyze real-world datasets</li>
              <li>Data storytelling and presentation</li>
              <li>Best practices in data analysis</li>
              <li>Building a portfolio of data analysis projects</li>
            </ul>
            <p>
              Throughout the bootcamp, students will work on practical
              assignments and real-world data analysis projects. They will
              receive hands-on experience, guidance, and support from our expert
              instructors, and they'll have the opportunity to build a strong
              portfolio showcasing theier data analysis skills. Upon completing
              the DevOsome Data Analysis Bootcamp, you'll be well-prepared to
              pursue a rewarding career in data analysis, make data-driven
              decisions, and tackle complex real-world challenges.
            </p>
          </div>
        </details>

        {/*     <div className="register">
        <Link to="/students/register">REGISTER</Link>
    </div> */}
      </div>
    </div>
  )
}

export default Program
