import React from 'react'

const About = (props) => (
    <div title='About US'>
      <section>
        <h4>
          <a href='#introduction' className='link'>
            #
          </a>
          Igniting Your Tech Journey at DevOsome
        </h4>
        <article id='introduction'>
          <p>
            At DevOsome, we are more than just a coding bootcamp – we are your
            dedicated partner on your journey to becoming a proficient tech
            professional. Our tailored programs are designed to empower
            individuals at every stage of their tech career, whether you are a
            beginner, an intermediate coder, or an aspiring data analyst.
          </p>
          <ul className='ul-list'>
            <li className='list'>
              Comprehensive coding bootcamps for all skill levels
            </li>
            <li className='list'>
              Data analysis programs to unlock insights from data
            </li>
            <li className='list'>
              Expert instructors and mentors for personalized guidance
            </li>
            <li className='list'>
              Real-world projects for practical hands-on experience
            </li>
            <li className='list'>
              Vibrant student communities and industry connections
            </li>
            <li className='list'>
              Career development support and job placement assistance
            </li>
            <li className='list'>
              Free coding workshops and short courses for beginners
            </li>
            <li className='list'>
              Online resources and documentation for continuous learning
            </li>
          </ul>

        
        </article>
      </section>
      <section>
        <h4>
          <a href='#about-express' className='link'>
            #
          </a>{' '}
          Fullstack Developer
        </h4>
        <article id='about-express'>
          <p>
          DevOsome Full-Stack Coding Bootcamps provide a guided path to success.
        Whether you are a complete beginner or an intermediate programmer, our
        well-structured, hands-on curricula are tailored to your skill level.
        You will dive into the fascinating world of web development, mastering
        core technologies such as: You will explore full-stack development,
        learn about React, Node.js, and build real-world applications. Our
        comprehensive programs give you the confidence to tackle complex coding
        projects and set you up for a thriving tech career.
          </p>
        </article>
      </section>
      <section>
        <h4>
          {' '}
          <a href='#packages' className='link'>
            #
          </a>{' '}
         Data Analysis
        </h4>
        <article id='packages'>
          <p>
          Our DevOsome Data Analysis Bootcamp is a transformative 6-month journey
        into the realm of data analysis. Guided by seasoned experts, you will
        master essential tools and technologies, including: - Python - NumPy -
        Pandas - Matplotlib This intensive program covers data wrangling, data
        visualization, statistical analysis, machine learning, and much more. By
        the end of the course, you will have the skills to dissect datasets,
        extract insights, and make data-driven decisions with precision. We
        offer not just knowledge and skills but the confidence to turn your
        coding dreams into reality or dive deep into the world of data analysis.
        At DevOsome, we are committed to your success, offering guidance,
        challenging your limits, and providing the support needed at every step.
        Our vibrant and innovative community of learners, industry
        professionals, and expert instructors is the perfect environment to grow
        your tech skills. Through coding bootcamps or data analysis programs, we
        aim to transform you into a tech professional ready to face the
        challenges of the ever-evolving tech world. Are you ready to embark on a
        tech journey with precision and purpose? Join DevOsome today, and
        together we will ignite your passion for coding or data analysis and set
        you on a path to a fulfilling tech career.
          </p>
        </article>
      </section>
    </div>
  )

About.propTypes = {}

export default About
