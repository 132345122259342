import React, { useEffect } from "react"
import { NavLink } from "react-router-dom"
import Student from "./Student"
import Spinner from "../components/Spinner"

const Students = (props) => {
  useEffect(() => {
    props.fetchData()
  }, [props.students])

  let renderContent

  if (props.students.length) {
    renderContent = props.students.map((student, i) => (
      <div
        key={student._id}
        className='students'>
        <NavLink to={`/students/${student._id}`}>
          <Student student={student} />
        </NavLink>
      </div>
    ))
  } else if (props.students.length === 0) {
    renderContent = <div>No students found</div>
  } else {
    renderContent = <Spinner />
  }

  return (
    <div title={"Students: " + props.students.length}>
      <div className='main-wrapper'>
        <div className='students'>{renderContent}</div>
      </div>
    </div>
  )
}

export default Students
