import React, { useState } from "react"
import axios from "axios"
import TextInputField from "../components/shared/TextInputField"
import TextAreaField from "../components/shared/TextAreaField"
import SelectListGroup from "../components/shared/SelectListGroup"
import { countries } from "../utils/countries"
import {useNavigate} from 'react-router-dom'

// Select options for status
const options = [
  { label: "* Select your title", value: "" },
  { label: "Junior Software Developer", value: "Junior Developer" },
  { label: "Fullstack Developer", value: "Fullstack Developer" },
  { label: "Front End Developer", value: "Fron End Developer" },
  { label: "Backend Developer", value: "Backend Developer" },
  { label: "Netwrok Engineer", value: "Network Engineer" },
  { label: "Devops Engineer", value: "Devops Engineer" },
  { label: "Cloud Engineer", value: "Cloud Engineer" },
  { label: "Senior Software  Developer", value: "Senior Developer" },
  { label: "Graphic Designer", value: "Graphic Designer" },
  { label: "Digital Marketer", value: "Digital Marketer" },
  { label: "Manager", value: "Manager" },
  { label: "Student", value: "Student" },
  { label: "Instructor", value: "Instructor" },
  { label: "Sales", value: "Sales" },
  { label: "Sales Manager", value: "Sales Manager" },
  { label: "Intern", value: "Intern" },
  { label: "Other", value: "Other" }
]
const genders = [
  { label: "* Gender", value: "" },
  { label: "Female", value: "Female" },
  { label: "Male", value: "Male" },
  { label: "Other", value: "Other" }
]
/* const batches = [
  { label: "* Select yout batch", value: "" },
  { label: "December-2017", value: "December-2018" },
  { label: "April-2018", value: "April-2018" },
  { label: "August-2018", value: "August-2018" },
  { label: "January-2019", value: "January-2019" },
  { label: "Other", value: "Other" }
] */

const trainings = [
  { label: "* Select program", value: "" },
  { label: "9 Months Web Development", value: "9 Months Web Development" },
  { label: "6 Months Web Development", value: "6 Months Web Development" },
  { label: "3 Months Web Development", value: "3 Months Web Development" },
  { label: "6 Months Fullstack", value: "6 Months Fullstack" },
  { label: "3 Months Fullstack", value: "3 Months Fullstack" },
  { label: "6 Months Data Analysis", value: "9 Months Data Analysis" },
  { label: "6 Months Mobile App Dev", value: "6 Months Mobile App Dev" },
  { label: "React Training", value: "React Training" },
  { label: "JavaScript Training", value: "JavaScript Training" },
  { label: "Python Training", value: "Python Training" },
  {
    label: "30DaysOfPython Certification Exam",
    value: "30DaysOfPython Certification Exam"
  },
  {
    label: "30DaysOfJavaScript Certification Exam",
    value: "30DaysOfJavaScript Certification Exam"
  },
  { label: "HTML and CSS", value: "HTML and CSS" },
  { label: "Other", value: "Other" }
]

const AddStudent = (props) => {
  const intitalState = {
    firstName: "",
    lastName: "",
    title: "",
    gender: "",
    tel: "",
    city: "",
    zipcode: "",
    streetName: "",
    email: "",
    dateOfBirth: "",
    country: "",
    nationality: "",
    training: "",
    skills: "",
    experiences: "",
    languages: "",
    github: "",
    linkedin: "",
    website: "",
    whySoftwareDeveloper: "",
    favoriteQuote: "",
    longTermVision: "",
    motivation: "",
    image: ""
  }
  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState(intitalState)
  const navigate = useNavigate()
  const onChange = (e) => {
    const { name, type, value } = e.target
    setFormData({ ...formData, [name]: value })

    if (type === "file") {
      const files = e.target.files
      setFormData({ ...formData, image: files[0] })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const url = "/api/v1.0.0/students"
    const config = {
      Accept: "application/json",
      headers: { "Content-Type": "multipart/form-data" }
    }
    const data = new FormData()
    for (const key in formData) {
      data.append(key, formData[key])
    }

    try {
      await axios({
        method: "post",
        url: url,
        data
      })
    } catch (err) {
      setErrors(err.response.data)
    }
    // props.fetchData()
    navigate("/students")
  }

  const {
    firstName,
    lastName,
    title,
    gender,
    dateOfBirth,
    tel,
    email,
    country,
    nationality,
    city,
    zipcode,
    streetName,
    training,
    experiences,
    skills,
    languages,
    github,
    linkedin,
    website,
    longTermVision,
    whySoftwareDeveloper,
    favoriteQuote,
    motivation
  } = errors

  return (
    <div title='Application Form'>
      <form
        onSubmit={onSubmit}
        noValidate>
        <div className='row'>
          <TextInputField
            label='First Name'
            id='firstName'
            type='text'
            name='firstName'
            value={formData.firstName}
            onChange={onChange}
            placeholder='First name'
            error={firstName}
          />

          <TextInputField
            label='Last Name'
            id='lastName'
            type='text'
            name='lastName'
            value={formData.lastName}
            onChange={onChange}
            placeholder='Last name'
            error={lastName}
          />
          <SelectListGroup
            label='Title'
            id='title'
            type='text'
            name='title'
            value={formData.title}
            onChange={onChange}
            placeholder='Title'
            options={options}
            error={title}
          />
        </div>
        <div className='row'>
          <SelectListGroup
            label='Gender'
            id='gender'
            name='gender'
            value={formData.gender}
            onChange={onChange}
            options={genders}
            error={gender}
          />

          <TextInputField
            label='Date of Birth'
            type='date'
            id='dateOfBirth'
            name='dateOfBirth'
            value={formData.dateOfBirth}
            onChange={onChange}
            placeholder='Date Of Birth'
            error={dateOfBirth}
          />

          <TextInputField
            label='Tel.'
            id='tel'
            name='tel'
            value={formData.tel}
            onChange={onChange}
            placeholder='Tel.+358'
            error={tel}
          />
        </div>
        <div className='row'>
          <TextInputField
            label='Email'
            type='email'
            id='email'
            name='email'
            value={formData.email}
            onChange={onChange}
            placeholder='Your Email'
            error={email}
          />

          <SelectListGroup
            label='Country of Origin'
            id='country'
            name='country'
            value={formData.country}
            onChange={onChange}
            options={countries}
            error={country}
          />
          <SelectListGroup
            label='Nationality'
            id='nationality'
            name='nationality'
            value={formData.nationality}
            onChange={onChange}
            options={countries}
            error={nationality}
          />
        </div>
        <div className='row'>
          <TextInputField
            label='City'
            id='city'
            type='text'
            name='city'
            value={formData.city}
            onChange={onChange}
            placeholder='City'
            error={city}
          />
          <TextInputField
            label='Zip Code'
            id='zipecode'
            type='text'
            name='zipcode'
            value={formData.zipcode}
            onChange={onChange}
            placeholder='Zipe Code'
            error={zipcode}
          />
          <TextInputField
            label='Street Name and Number'
            id='streetName'
            type='text'
            name='streetName'
            value={formData.streetName}
            onChange={onChange}
            placeholder='Street Name'
            error={streetName}
          />
        </div>

        <hr />
        <SelectListGroup
          label='Training Programs'
          id='training'
          name='training'
          value={formData.training}
          onChange={onChange}
          options={trainings}
          error={training}
        />
        <TextAreaField
          label='Experiences'
          id='experiences'
          name='experiences'
          cols='120'
          rows='2'
          value={formData.experiences}
          onChange={onChange}
          placeholder='Experiences separated by comma eg: Software Engineer, Graphics Designer'
          error={experiences}
        />
        <TextAreaField
          label='Skills'
          id='skills'
          name='skills'
          cols='120'
          rows='5'
          value={formData.skills}
          onChange={onChange}
          placeholder='Skills separated by comma eg: HTML, CSS, JS'
          error={skills}
        />

        <TextAreaField
          label='Languages'
          id='languages'
          name='languages'
          cols='120'
          rows='2'
          value={formData.languages}
          onChange={onChange}
          placeholder='Languages separated by comma eg: English, French, Spanish, Finnish'
          error={languages}
        />
        <div className='row'>
          <TextInputField
            label='GitHub'
            id='github'
            type='text'
            name='github'
            value={formData.github}
            onChange={onChange}
            placeholder='GitHub'
            error={github}
          />
          <TextInputField
            label='LinkedIn'
            id='linkedin'
            type='text'
            name='linkedin'
            value={formData.linkedin}
            onChange={onChange}
            placeholder='LinkedIn'
            error={linkedin}
          />

          <TextInputField
            label='Portfolio website'
            id='website'
            type='text'
            name='website'
            value={formData.website}
            onChange={onChange}
            placeholder='Portfolio website'
            error={website}
          />
        </div>

        <TextAreaField
          label='Why Software Developer'
          id='whySoftwareDeveloper'
          name='whySoftwareDeveloper'
          cols='120'
          rows='2'
          value={formData.whySoftwareDeveloper}
          onChange={onChange}
          placeholder='Why Software Developer'
          error={whySoftwareDeveloper}
        />
        <TextAreaField
          label='Long term vision'
          id='longTermVision'
          name='longTermVision'
          cols='120'
          rows='2'
          value={formData.longTermVision}
          onChange={onChange}
          placeholder='Long term vision'
          error={longTermVision}
        />
        <TextAreaField
          label='Favorite Quote'
          id='favoriteQuote'
          name='favoriteQuote'
          cols='120'
          rows='1'
          value={formData.favoriteQuote}
          onChange={onChange}
          placeholder='Favorite Quote'
          error={favoriteQuote}
        />

        <TextAreaField
          label='Your Motivation'
          id='motivation'
          name='motivation'
          cols='120'
          rows='15'
          value={formData.motivation}
          onChange={onChange}
          placeholder='Motivation'
          error={motivation}
        />

        <div className='form-group file-upload'>
          <input
            type='file'
            name='image'
            id='image'
            onChange={onChange}
            placeholder='Upload an image'
            accept='image/*'
            size='600'
            // style={{ display: 'none' }}
          />
          {/* <label htmlFor='image'>
            {' '}
            <i class='fas fa-upload'></i>
          </label> */}
        </div>

        <button
          className='btn'
          type='submit'
          id='submit-button'>
          Send
        </button>
      </form>
    </div>
  )
}

// AddStudent.propTypes = {
//   title: PropTypes.string.isRequired,
// }

export default AddStudent
