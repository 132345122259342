import React from "react"

const Spinner = (props) => (
  <div className='main-wrapper'>
    <h1>Loading.</h1>
  </div>
)


export default Spinner
