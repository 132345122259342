import React from "react"
import Layout from "./Layout"

const Contact = (props) => (
    <div title='Contact'>
      <address>
        Express App <br />
        Finland, Helsinki <br />
        02210 <br />
        +358451000000 <br />
        Finland
      </address>
    </div>
  )

export default Contact
