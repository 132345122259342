import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import logo from '../../assets/images/devosome_logos/devosome_shot_logo_transparent.png'


const Ul = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  list-style: none;
  z-index: 20;
  li {
    padding: 18px 10px;
  }
  li a {
    font-weight:900;
    color:#A24FC7;
    padding: 10px 15px;
    border-radius: 2px;
    text-align: center;
    width: 100%;
  }
  .active {
    /* background-color: #00e091; */
    border: 1px solid #00e091;
    border: 1px solid #A24FC7;
    border-radius: 2px;

  }
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: rgba(59, 47, 99, 0.9);
    background-color: rgba(199, 184, 216, 1);
    position: fixed;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    margin: 0;
    li {
      color: #fff;
    }
  }
`

const RightNav = ({ open }) => {
  return (
    <Ul open={open}>
      <li>
        <NavLink activeclassname='active' to='/'>
          Home
        </NavLink>
      </li>
      <li>
        <NavLink activeclassname='active' to='/about'>
          About
        </NavLink>
      </li>
      {/* <li>
        <NavLink activeclassname='active' to='/contact'>
          Contact
        </NavLink>
      </li> */}
      {/* <li>
        <NavLink exact activeClassName='active' to='/students/register'>
          Apply
        </NavLink>
      </li> */}
      {/* <li>
        <NavLink activeclassname='active' to='/blogs'>
         Blogs
        </NavLink>
      </li> */}
      <li>
        <NavLink activeclassname='active' to='/programs'>
          Programs
        </NavLink>
      </li>
   
    </Ul>
  )
}

const Nav = styled.nav`
  width: 100%;
  height: 150px;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .nav-logo {
    max-width: 35%;
  }
  .logo {
    font-size: 26px;
  }
  .brand-name {
    /* background-color: #3b2f63; */
    color: white;
    border-radius: 2px;
    padding: 10px;
  }
  @media(max-width:600px){
    .nav-logo {
    max-width: 25%;
  }
  }
`

const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 15px;
  right: 20px;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index:50;
  display:none;
  @media(max-width:768px){
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  justify-items: center;
 
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => (open ? '#eee' : '#333333')};
    border-radius: 10px;
     transform-origin:1px;
     transition: all 0.3s linear;
     &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
     &:nth-child(2) {
      transform: ${({ open }) => (open ? 'rotate(100%)' : 'rotate(0)')};
      opacity:${({ open }) => (open ? 0 : 1)}
    }
     &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`

const Burger = (props) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <RightNav open={open} />
    </>
  )
}
const Navbar = (props) => {
  return (
    <Nav>
      <div className='logo'>
        <a href='/' className='brand-name'>
      
          <img src = {logo}  className='nav-logo' alt ="Devosome Logo"/>
        </a>
      </div>
      <Burger />
    </Nav>
  )
}

export default Navbar

