import React from "react"

const Footer = (props) =>  (
    <footer>
      <div className='footer-wrapper'>
        <h3 className='page-title'>DevOsome &copy; {new Date().getFullYear()}</h3>
        <small>
        Igniting Your Tech Journey at DevOsome
        </small>
        <div>
      {/* <a
        href='https://github.com/Asabeneh'
        rel='noopener noreferrer'
        target='_blank'
      >
        <i className='fab fa-github-square'></i>
      </a> */}
      <a
        href='https://www.linkedin.com/company/devosome/'
        rel='noopener noreferrer'
        target='_blank'
      >
        <i className='fab fa-linkedin'></i>
      </a>
      {/* <a
        href='https://twitter.com/Asabeneh'
        rel='noopener noreferrer'
        target='_blank'
      >
        <i className='fab fa-twitter-square'></i>
      </a> */}
    </div>
   {/*  <div className='arrow-icon-wrapper'>
      <a href='#top'>
      <i className="fas fa-arrow-alt-circle-up"></i> 
        <i className='fas fa-chevron-circle-up'></i>
      </a>
    </div> */}
      </div>
      
  
    </footer>
  )

export default Footer
