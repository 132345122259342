import React from 'react'
import moment from 'moment'
import Moment from 'react-moment'
import { format, formatISO9075 } from 'date-fns'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import './Post.scss'
import { Container, Row, Col } from 'reactstrap'

const Post = ({
  title,
  summary,
  content,
  comments,
  likes,
  views,
  createdAt,
  cover,
  user,
  id,
  status
}) => {
  const postCompleted = status.completed ? 'postCompleted':''
  return (
    <Row className={`mt-5 mb-5 ${postCompleted}`}>
      <Col className='col-4'>
        <Link to={`/posts/${id}`}>
          <img
          className='img-fluid'
            src={cover.url}
            alt=''
          />
        </Link>
      </Col>

      <Col  className={'col-8' }>
        <Link to={`/posts/${id}`}>
          <h2 className='title'>{title}</h2>
        </Link>
          <Row className='mb-2'>
          <Col className='d-flex align-items-center'>
          <img
              src={user.avatar}
              alt={user.firstName}
              className='me-2'
              loading='lazy'
              lazy
              style={{width:'5%', borderRadius:'50%'}}
            />
            <span>{user.firstName} {user.lastName}</span>
          </Col>
          <Col>  <time className='text-muted'>{format(new Date(createdAt), 'MMM d, yyyy HH:mm')}</time></Col>
          </Row>
         

        {/* <p className={'postStyles.summary'}>
          {summary && summary.substring(0, 200)} <br />{' '}
         
        </p> */}
        {/* <Link
            to={`/posts/${id}`}
            className={postStyles.readMore}
          >
            Read more
          </Link> */}
          <Row>
            <Col>
            <div className="postStat">
            <span><i className='far fa-regular fa-heart'></i>{' '}
            {likes.length > 0 && likes.length}</span>
           <span> <i className='far fa-regular fa-comment'></i>{' '}
            {comments.length > 0 && comments.length}</span>
            <span>  {views && (
              <>
              <i className='far fa-regular fa-eye'></i> 
             { views}
              </>
             
            ) 
            
            }</span>
            </div>
            </Col>
          </Row>
      </Col>
     
    </Row>
  )
}

export default Post
