import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/landing/Home'
import About from './pages/About'
import Contact from './components/Contact'
import Students from './pages/Students'
import AddStudent from './pages/AddStudent'
import Layout from './components/Layout'
import Blogs from './components/post/Posts'


import Program from './pages/Program'

const App = () => {
  const [students, setStudents] = useState([])
  const [error, setError] = useState({})

  const fetchData = () => {
    const url = '/api/v1.0.0/students'
    axios
      .get(url)
      .then((response) => {
        setStudents(response.data)
      })
      .catch((err) => {
        console.log(err.response.data)
        setError(err.response.data)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='contact' element={<Contact />} />
          <Route path='programs' element={<Program />} />
          <Route
            path='students'
            element={() => (
              <Students students={students} fetchData={fetchData} />
            )}
          />
          <Route path='students/register' element={<AddStudent />} />
          <Route path='blogs' element = {<Blogs />} />
        </Route>

        {/*  
        <Route
          exact
          path='/students/edit/:id'
          render={(props) => (
            <EditStudent
              {...props}
              fetchData={this.fetchData}
            />
          )}
        />

        <Route
          exact
          path='/students/:id'
          component={(props) => (
            <StudentDetail
              {...props}
              deleteStudent={this.deleteStudent}
              fetchData={this.fetchData}
              students={students}
            />
          )}
        />
        <Route
          path='/students'
          render={() => (
            <Students
              students={students}
              fetchData={this.fetchData}
            />
          )}
        />
        <PrivateRoute
          exact
          path='/admin/dashboard'
          component={(props) => <Admin {...props} />}
        /> */}
        {/*   <Route
          exact
          path='/user/activation/:id'
          component={(props) => <UserActivation {...props} />}
        /> */}
        {/*  <Route
          exact
          path='/admin/signup'
          component={(props) => <Signup />}
        />
        <Route
          exact
          path='/admin/signin'
          component={(props) => <Signin title='Sign in to your account' />}
        /> */}
        {/*    <Route
          exact
          path='/forgotpassword'
          component={(props) => <ForgotPassword {...props} />}
        />
        <Route
          exact
          path='/resetpassword/:id'
          component={(props) => <ResetPassword {...props} />}
        />  */}
        {/*  <Route
          exact
          path='/programs'
          component={Program}
        />
        <Route
          exact
          path='/activation-message'
          component={(props) => <ActivationEmailMessage {...props} />}
        />
     

        <Route
          exact
          path='/check-email'
          component={(props) => <CheckYourEmail {...props} />}
        /> */}
      </Routes>
    </BrowserRouter>
  )
}

export default App
