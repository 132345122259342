import React from "react"
import PropTypes from "prop-types"
import Header from "./header/Header"
import Footer from "./footer/Footer"
import {Outlet} from 'react-router-dom'

const Layout = ({title}) =>  (
    <>
      <Header title={title}/>
      <main>
        <div className='main-wrapper'>
          <Outlet />
        </div>
      </main>
      <Footer />
    </>
  )


Layout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default Layout
