import React from 'react'
import Navbar from './Navbar'
import PropTypes from 'prop-types'
import './Header.scss'

const Header = ({ title }) => (
  <header>
    <div className='header-wrapper'>
      <Navbar />
      <div>
    {/* {
      title &&  <h3 className='page-title'>{title}</h3>
    }
  */}
      </div>
     
    </div>
  </header>
)

Header.propTypes = {
  title: PropTypes.string,
}

export default Header
