import React from "react"
import moment from "moment"

const Student = (props) => {
  if (Object.keys(props).length < 1) {
    return <h1>Loading...</h1>
  }
  const imageStyles = {
    borderRadius: "50%"
  }

  const renderStatus = (student) => {
    let status =
      (student.invited &&
        student.confirmed &&
        student.interviewed &&
        student.accepted) ||
      student.accepted ? (
        <small>
          {" "}
          <i class='fas fa-check-circle'></i>
        </small>
      ) : (
        student.invited &&
        !student.confirmed &&
        !student.accepted && <small> 😕</small>
      )

    return status
  }

  return (
    <>
      <div className='student'>
        <div>
          {props.student.imageUrl.length > 1 ? (
            <img
              src={props.student.imageUrl}
              style={imageStyles}
              className='card-image'
              alt = {'student profile'}
            />
          ) : (
            <i className='fas fa-user'></i>
          )}
          <div className='profile-category'>
            <h4>
              {props.student.firstName} {props.student.lastName}
              {renderStatus(props.student)}
            </h4>
          </div>
          <small>{props.student.title}, </small>
          <small>{props.student.country} </small>
        </div>
        <div className='profile-category'>
          <h4>Skills</h4>
        </div>

        <div className='skills'>
          {props.student.skills.map((skill) => (
            <div>
              <span className='skill'>{skill}</span>
            </div>
          ))}
        </div>
        <div>
          <small>
            <i className='far fa-clock'></i> Joined on{" "}
            {moment(props.student.createdAt).format("ll")}{" "}
          </small>
        </div>
      </div>
    </>
  )
}

export default Student
