import React from 'react'
import htmLogo from '../../assets/images/html_logo.png'
import cssLogo from '../../assets/images/css_logo.png'
import jsLogo from '../../assets/images/js_logo.png'
import reactLogo from '../../assets/images/react_logo.png'
import mongodb from '../../assets/images/mongodb.png'
import mongoose from '../../assets/images/mongoose.png'
import nodeLogo from '../../assets/images/node_logo.png'
import pythonLogo from '../../assets/images/python_logo.webp'
import './Home.scss'
import programmer from '../../assets/images/programmer.svg'
import sassLogo from '../../assets/images/sass_logo.png'
import reduxLogo from '../../assets/images/redux_logo.png'
import typescriptLogo from '../../assets/images/typescript_logo.png'
import awsLogo from '../../assets/images/aws_logo.webp'
import mysqlLogo from '../../assets/images/mysql_logo.png'

const Home = (props) => (
  <>
    <section className='hero'>
      <div className='hero-flex'>
        <div className='hero-left'>
          <h1>
            Igniting Your Tech <br /> Journey at <span>DevOsome</span>
          </h1>
          <article>
            <p>
              At DevOsome, we are more than just a coding bootcamp – we are your
              dedicated partner on your journey to becoming a proficient tech
              professional. Our tailored programs are designed to empower
              individuals at every stage of their tech career, whether you are a{' '}
              <strong>beginner</strong>, an <strong>intermediate</strong> coder,
              or an <strong>aspiring data analyst</strong>.
            </p>
          </article>
          {/* <button>REGISTER</button> */}
        </div>

        <div className='hero-right'>
          <img src={programmer} alt='programmer' />
        </div>
      </div>
    </section>
    <div className='web-techs'>
      <h2>Our Core Technologies</h2>
      <section>
        <h3 className='web_techs__title'>Front End Technologies</h3>
        <article className='web-tech__images'>
          {/* <img src={htmLogo} alt='HTML Logo' title='HTML5 Logo' />
          <img src={cssLogo} alt='CSS Logo' title='CSS Logo' />
          <img src={sassLogo} alt='Sass Logo' title='Sass Logo' /> */}
          <img src={jsLogo} alt='JavaScript Logo' title='JavaScript Logo' />
          <img
            src={typescriptLogo}
            alt='TypeScript Logo'
            title='TypeScript Logo'
          />
          <img src={reactLogo} alt='React Logo' title='React Logo' />
          <img src={reduxLogo} alt='Redux Logo' title='Redux Logo' />
        </article>
      </section>
      <section>
        <h3 className='web_techs__title'>Backend Technologies</h3>
        <article className='web-tech__images'>
          {/* <img src={mongodb} alt='MongoDB Logo' />
          <img src={mongoose} alt='Mongoose Logo' /> */}
          <img src={nodeLogo} alt='Node Logo' />
          <img src={pythonLogo} alt='Python Logo' title='Python Logo' />

          <img src={awsLogo} alt='AWS Logo' title='AWS Logo' />

          <img src={mysqlLogo} alt='MySQL Logo' title='MySQL Logo' />
        </article>
      </section>
    </div>
  </>
)

export default Home
